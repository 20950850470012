<template>
  <div class="prozess-wrapper">
    <div class="prozess">
      <div>
        <div v-if="!claimed">
          <TaskBlocker :assignee="data.assignee" />
        </div>
        <div v-if="!isLoading">
          <CustomerDetails
            v-if="customerAddress !== null"
            :data="customerAddress"
            parseSalutation
            title="Kundeninformationen"
          />
          <DisplayContainer v-if="contractId" :label="'Vertragsinformationen'">
            <DisplaySubContainer label="Allgemein">
              <div class="input-row">
                <DataDisplay label="Vertragsnummer">
                  <div>
                    {{ contractId }}
                  </div>
                </DataDisplay>
                <DataDisplay label="Portierungsdatum">
                  <div v-if="portingDate">
                    {{
                      new Date(portingDate).toLocaleDateString('de-De', {
                        weekday: 'long',
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit'
                      })
                    }}
                  </div>
                </DataDisplay>
              </div>
            </DisplaySubContainer>
            <DisplaySubContainer label="Telefonie">
              <div class="input-row">
                <DataDisplay label="Telefonnummern">
                  <div v-if="phoneNumbers">
                    <div v-for="phone in phoneNumbers" :key="phone">
                      {{ phone }}
                    </div>
                  </div>
                </DataDisplay>
              </div>
            </DisplaySubContainer>
          </DisplayContainer>

          <DisplayContainer
            v-for="error in pNotificationErrors"
            :key="error.number"
            :label="
              'Fehler in Rufnummer ' +
                error.number +
                ': ' +
                pNotificationErrorTypeDisplayName[error.pNotificationErrorType]
            "
          >
            <v-tabs
              v-model="selectedResolutionOptions[error.number].selectedOption"
              :background-color="'var(--goe-background-highlight)'"
              :disabled="!claimed"
            >
              <v-tabs-slider color="primary"></v-tabs-slider>
              <v-tab
                v-for="tabOption in resolutionTabOptions"
                :key="tabOption.resolutionOption"
                :href="'#' + tabOption.resolutionOption"
                :disabled="!claimed"
              >
                {{ tabOption.title }}
              </v-tab>
            </v-tabs>
            <v-tabs-items
              v-model="selectedResolutionOptions[error.number].selectedOption"
              :color="'var(--goe-background-highlight)'"
            >
              <v-tab-item
                value="tryAgain"
                :color="'var(--goe-background-highlight)'"
              >
                <v-card
                  flat
                  :color="'var(--goe-background-highlight)'"
                  class="padding-top-1"
                >
                  <DisplaySubContainer label="Lösungsbeschreibung">
                    Erneut versuchen die P-Meldung zu versenden.
                  </DisplaySubContainer>
                </v-card>
              </v-tab-item>
              <v-tab-item
                value="importedManually"
                :color="'var(--goe-background-highlight)'"
              >
                <v-card
                  flat
                  :color="'var(--goe-background-highlight)'"
                  class="padding-top-1"
                >
                  <DisplaySubContainer label="Lösungsbeschreibung">
                    Rufnummer wurde manuell importiert oder ist anderweitig
                    schon importiert und soll im weiteren Verlauf des Prozesses
                    provisioniert werden.
                  </DisplaySubContainer>
                </v-card>
              </v-tab-item>
              <v-tab-item value="changeNumber">
                <v-card
                  flat
                  :color="'var(--goe-background-highlight)'"
                  class="padding-top-1"
                >
                  <DisplaySubContainer label="Lösungsbeschreibung">
                    Rufnummer ist falsch und soll korrigiert werden.
                  </DisplaySubContainer>
                  <DisplaySubContainer
                    label="Korrigierte Rufnummer"
                    class="padding-top-1"
                  >
                    <v-text-field
                      v-model="selectedResolutionOptions[error.number].number"
                      :dense="dense"
                      single-line
                      :rules="[
                        (val) =>
                          (!!val && !isNaN(val)) ||
                          'Die Telefonnummer muss numerisch sein!',
                        (val) =>
                          (!!val && val.startsWith('0')) ||
                          'Die Telefonnummer muss mit 0 beginnen!',
                        (val) =>
                          (!!val && val.length >= 6 && val.length <= 14) ||
                          'Die Telefonnummer muss zwischen 6 und 14 Zeichen lang sein!'
                      ]"
                      :disabled="!claimed"
                    ></v-text-field>
                  </DisplaySubContainer>
                </v-card>
              </v-tab-item>
              <v-tab-item value="dontImport">
                <v-card
                  flat
                  :color="'var(--goe-background-highlight)'"
                  class="padding-top-1"
                >
                  <DisplaySubContainer label="Lösungsbeschreibung">
                    Rufnummer kann nicht importiert werden und soll nicht weiter
                    prozessiert werden. Die Rufnummer wird aus dieser
                    Prozessinstanz herausgenommen. Im varioCRM wird nichts
                    geändert.
                  </DisplaySubContainer>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </DisplayContainer>

          <div class="group">
            <div>Notizen</div>
            <div>
              <v-textarea
                v-model="memo"
                auto-grow
                clearable
                clear-icon="mdi-delete"
                counter="70"
                :rules="[
                  (v) =>
                    v == null ||
                    v.length <= 70 ||
                    'Die Notiz darf maximal 70 Zeichen lang sein!',
                  (v) =>
                    !/[^\u0000-\u00ff]/g.test(v) ||
                    'Unerlaubte Zeichen vorhanden: ' +
                    v.replace(/[\u0000-\u00ff]/g, '')
                ]"
                name="memo-input"
                placeholder="Notiz eingeben..."
                :disabled="!claimed"
              />
            </div>
          </div>
        </div>
        <div v-else>
          <Loader />
        </div>
      </div>
      <div v-if="!isLoading" class="buttons-wrapper">
        <v-btn
          :disabled="isLoading || !claimed"
          color="secondary"
          large
          @click="saveTask()"
        >
          Memo Speichern
        </v-btn>
        <v-btn
          :disabled="isLoading || !claimed || completeButtonDisabled"
          color="primary"
          large
          @click="completeTask()"
        >
          Aufgabe abschließen
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationObject from '@/main/NotificationObject.js';

import { HTTP } from '@/main/httpClient.js';
import { DateTimeFormatter, LocalDateTime } from '@js-joda/core';

import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import DisplaySubContainer from '@/components/elements/DisplaySubContainer.vue';
import DataDisplay from '@/components/elements/DataDisplay.vue';

import CustomerDetails from '@/components/CustomerDetails.vue';
import CarrierSelection from '@/components/CarrierSelectionVuetify.vue';
import Loader from '@/components/elements/Loader.vue';
import TaskBlocker from '../TaskBlocker.vue';
import DatePicker from '@/components/elements/DatePicker.vue';
import TextLink from '@/components/elements/TextLink.vue';

export default {
  name: 'ActionHandlePNotificationErrors',

  components: {
    DisplayContainer,
    DisplaySubContainer,
    DataDisplay,
    CustomerDetails,
    CarrierSelection,
    TaskBlocker,
    Loader,
    DatePicker,
    TextLink
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    isLoading: false,
    customerAddress: null,
    contractId: null,
    customerId: null,
    portingDate: null,
    memo: null,
    phoneNumbers: null,
    pNotificationErrors: null,
    errorResolveInstructions: null,
    pNotificationErrorTypeDisplayName: {
      OTHER: 'Unbekannter Fehler',
      OCS_NUMBER_DOES_NOT_EXIST: 'oCS: Rufnummer existiert nicht',
      OCS_NUMBER_AREA_CODE_DOES_NOT_FIT: 'oCS: Vorwahl passt nicht zur Adresse',
      OCS_NUMBER_ACTIVE: 'oCS: Rufnummer schon importiert',
      VARIOCRM_NUMBER_ACTIVE_ELSEWHERE:
        'varioCRM: Rufnummer an anderem Vertrag aktiv'
    },
    resolutionTabOptions: {
      tryAgain: {
        title: 'Import erneut Versuchen',
        disabled: false,
        resolutionOption: 'tryAgain',
        backend_id: 'TRY_AGAIN'
      },
      importedManually: {
        title: 'Rufnummer manuell importiert',
        disabled: false,
        resolutionOption: 'importedManually',
        backend_id: 'MANUAL_IMPORT'
      },
      changeNumber: {
        title: 'Rufnummer Ändern',
        disabled: false,
        resolutionOption: 'changeNumber',
        backend_id: 'CHANGED_NUMBER'
      },
      dontImport: {
        title: 'Rufnummer nicht importieren',
        disabled: false,
        resolutionOption: 'dontImport',
        backend_id: 'NO_PROVISIONING'
      }
    },
    selectedResolutionOptions: {}
  }),
  computed: {
    username() {
      return this.$store.getters.getUsername;
    },
    claimed() {
      return this.data.assignee === this.username;
    }
  },
  mounted: function () {
    this.loadTaskData(this.data.id);
  },
  created() {},
  methods: {
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    validateInputs() {},
    loadTaskData: function (id) {
      this.isLoading = true;
      HTTP.get(`/task/${id}/variables`)
        .then((resp) => {
          const formatter = DateTimeFormatter.ofPattern(
            "yyyy-MM-dd'T'HH:mm:ss.SSSZ"
          );
          this.customerId = resp.data.customerId.value;
          this.contractId = resp.data.contractId.value;
          this.portingDate = LocalDateTime.parse(
            resp.data.portingDate.value,
            formatter
          );
          this.pNotificationErrors = resp.data.pNotificationErrors.value;

          this.selectedResolutionOptions = this.pNotificationErrors.reduce(
            function (map, error) {
              map[error.number] = {
                selectedOption: 'tryAgain',
                number: error.number
              };
              return map;
            },
            {}
          );

          if (resp.data.memo !== undefined) {
            this.memo = resp.data.memo.value;
          }
        })
        .then(() => {
          return Promise.allSettled([
            this.fetchAddressInfo(this.contractId),
            this.fetchContractInfo(this.contractId)
          ]);
        })
        .catch((e) => {
          this.showMessage(
            'error',
            'Die Kundendaten konnten nicht geladen werden!'
          );
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    fetchAddressInfo(contractId) {
      return HTTP.get(`/customer/contactAddress/${contractId}`).then((res) => {
        this.customerAddress = { ...res.data, customerId: this.customerId };
      });
    },
    fetchContractInfo(contractId) {
      return HTTP.get(`/customer/${contractId}`).then((res) => {
        // get phone numbers
        this.phoneNumbers = res.data.phoneNumbers;
      });
    },
    generateErrorHandlingDto() {
      var dto = {};
      for (const [key, value] of Object.entries(
        this.selectedResolutionOptions
      )) {
        dto[key] = {
          action: this.resolutionTabOptions[value.selectedOption].backend_id
        };
        if (value.selectedOption === 'changeNumber') {
          dto[key].changedNumber = value.number;
        }
      }
      return dto;
    },
    completeTask: function () {
      HTTP.post(
        `/portingTask/completePNotificationErrorHandlingTask/${this.data.id}`,
        this.generateErrorHandlingDto(),
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
        .then((resp) => {
          const message = 'Der Abschluss der Aufgabe war erfolgreich!.';
          this.showMessage('info', message);
          this.$store.commit('triggerTasksRefresh');
          this.$emit('onClose');
        })
        .catch(() =>
          this.showMessage(
            'error',
            'Der Abschluss der Aufgabe war nicht erfolgreich!'
          )
        );
    },
    saveTask: function () {
      HTTP.post(`/task/${this.data.id}/localVariables`, {
        modifications: {
          memo: {
            value: this.memo,
            type: 'String'
          }
        }
      });
    }
  }
};
</script>

<style scoped>
.buttons-wrapper {
  display: grid;
  grid-template-columns: auto max-content;
  grid-gap: var(--goe-spacing-1);
}

.buttons-wrapper .v-btn {
  width: max-content;
  margin-left: auto;
  margin-right: var(--goe-spacing-0);
}

.input-field {
  background: var(--goe-background-highlight);
}

option:first-child {
  display: none;
}
</style>
